<template>
    <v-container fluid tag="section">

        <div class="text-end">
            <v-btn outlined class="font-weight-bold me-2" :to="`/settings/general`">{{ $t('Cancel') }}</v-btn>
            <v-btn color="purple" class="font-weight-bold me-0" @click="onSubmit" :loading="loading"
                :disabled="loading">{{ $t('Save') }}</v-btn>
        </div>

        <v-row>
            <v-col cols="12">
                <v-card class="rounded-lg mt-2">
                    <v-card-text class="px-0 py-0">
                        <v-form>
                            <v-container>
                                <v-card>
                                    <v-card-title>{{ $t('Users') }}</v-card-title>
                                    <v-card-text>
                                        <v-text-field v-model="item.company_initial_credit" :label="$t('Initial credit upon registration')"></v-text-field>
                                    </v-card-text>
                                </v-card>
                                <v-card>
                                    <v-card-title>{{ $t('Transactions') }}</v-card-title>
                                    <v-card-text>
                                        <v-select v-model="item.transactions_default_removebackground_item" :label="$t('Default remove background item')" :items="removebackground_items"
                                            :item-text="item =>`${item.id} - ${item.name}`" item-value="id"></v-select>
                                    </v-card-text>
                                    <v-card-text>
                                        <v-select v-model="item.transactions_default_sendemail_item" :label="$t('Default send email item')" :items="sendemail_items"
                                            :item-text="item =>`${item.id} - ${item.name}`" item-value="id"></v-select>
                                    </v-card-text>
                                </v-card>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import settingService from '@/services/settingService'
import itemService from '@/services/itemService'

export default {
    data() {
        return {
            loading: false,
            item: {
                company_initial_credit: null,
                transactions_default_removebackground_item: null,
                transactions_default_sendemail_item: null,
            },
            removebackground_items: [],
            sendemail_items: []
        }
    },
    async created() {
        await this.getSetting()
        await this.getItems()
    },
    methods: {
        async getSetting() {
            try {
                const result = await settingService.get('general')
                result.data.transactions_default_removebackground_item = Number(result.data.transactions_default_removebackground_item)
                result.data.transactions_default_sendemail_item = Number(result.data.transactions_default_sendemail_item)
                this.item = result.data
            } catch (error) {
                this.$dialog.message.error(error.message)
            }
        },
        async getItems() {
            try {
                const result = await itemService.getAll({
                    operationAlias: ['removebackground', 'sendemail'],
                    public: true,
                    active: true,
                })
                this.removebackground_items = result.data.filter(item => item.operation.alias === 'removebackground')
                this.sendemail_items = result.data.filter(item => item.operation.alias === 'sendemail')
            } catch (error) {
                this.$dialog.message.error(error.message)
            }
        },
        async onSubmit() {
            this.loading = true
            try {
                await settingService.edit('general', this.item)
                this.$dialog.message.success(this.$t('Success'))
            } catch (error) {
                this.$dialog.message.error(error.message)
            }
            this.loading = false
        }
    },
}
</script>